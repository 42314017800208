<template>
  <header>
    <h1>RS Dummyclient v1.3.0</h1>
    <div v-if="store.user.username" class="userinfo">
      Logged in as: {{ store.user.username }} - <a href="#" @click.stop="logout">Log out</a><br>
      Balance: {{ store.user.balance.total }} SEK
    </div>
  </header>
  <MainMenu />
  <main>
    <UserLogin v-if="store.page == 'login'" />
    <LoggedIn v-if="store.page == 'loggedin'" />
    <Jackpot v-if="store.page == 'jackpot'" />
    <Raffle v-if="store.page == 'raffle'" />
  </main>
</template>

<script>
import MainMenu from './components/MainMenu.vue'
import UserLogin from './components/UserLogin.vue'
import LoggedIn from './components/LoggedIn.vue'
import Jackpot from './components/Jackpot.vue'
import Raffle from './components/Raffle.vue'
import store from './store.js';

export default {
  name: 'App',
  data() {
    return {
      store
    }
  },
  components: {
    MainMenu,
    UserLogin,
    LoggedIn,
    Jackpot,
    Raffle,
  },
  methods: {
    logout() {
      store.page = 'login';
      store.user = {};
    }
  }
}
</script>

<style>
:root {
  --color-green: #9fd0b0;
  --color-yellow: #dbb230;
  --color-blue: #1b2c4d;
  --color-text-light: #fff;
  --color-text-dark: var(--color-blue);
  --color-disabled: grey;
}
html {
  height: 100%
}
body {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  min-height: 100%;
  background: #fff;
  color: #1b2c4d;
  margin:0;
  padding:0;
}
#app {
  color: var(--color-text-dark);
}

header {
  background: var(--color-green);
  padding: 10px 20px;
  display: flex;
}
header h1 {
  margin: 0;
  padding: 0;
}
header .userinfo {
  font-size: 0.85em;
  margin-left: auto;
  text-align: right;
}
header .userinfo a {
  text-decoration: underline;
}
main {
  padding: 20px;
}

h1, h2, h3, h4 {
  font-family: Aleo;
  font-weight: 700;
}
h5,h6 {
  font-family: Montserrat;
  font-weight: 600;
}
button, .button {
  border: 0;
  font-size: 1em;
  padding: 0.5em 1em;
  background: var(--color-blue);
  color: var(--color-text-light);
  padding: 0.5em 1em;
  border-radius: 0.3em;
}
button:disabled, .button:disabled, button.disabled, .button.disabled {
  background: var(--color-disabled);
}
input[type=text], input[type=number] {
  padding: 0.5em;
}


.overlay-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 1000;
}
.overlay-loading:before {
  position: absolute;
  left: 50%;
  top: 50%;
  border: 4px solid var(--color-text-light);
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  width: 60px;
  height: 60px;
  content: '';
  animation: rotate360deg 1.25s infinite linear;
}

@keyframes rotate360deg {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



</style>
